export const KNOWN_CODES = [
  "STIFMO",
  "STIFBI",
  "STIHCO",
  "STICPR",
  "STISDE",
  "STICCO",
  "STIFSO",
  "TAMHDA",
  "TAMBDA",
  "TAMCDA",
  "TAMSMO",
  "TAMNMO",
  "TAMKDI",
  "TAMKWE",
  "TAMHBI",
  "TAMEHO",
  "PREHNE",
  "TOYNNE",
  "PREHHA",
  "PRENHA",
  "PREVHA",
  "PRECHB",
  "PRENME",
  "10118LEIARA",
  "CHRIWA",
  "LEIARA",
  "LEICAB",
  "LEICCA",
  "LEICRA",
  "LEIFWE",
  "LEIHRA",
  "LEILRA",
  "LEINCA",
  "LEITCA",
  "LEITHR",
  "LEITR2",
  "LEITRA",
  "LEITRL",
  "LEITWE",
  "LEIVRA",
  "LETCRA",  
]
