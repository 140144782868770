import { CatalogItem } from "../lib/interfaces";
export const PremierCatalog: CatalogItem[] = [
  {
    code: "ST650",
    name: "Sport-Tek® Polo Shirt",
    fullname: "Sport-Tek® Sport-Wick Polo",
    colors: ["Black", "Iron Grey", "White"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 22.99,
      Medium: 22.99,
      Large: 22.99,
      XLarge: 22.99,
      "2XLarge": 24.98,
      "3XLarge": 28.98,
      "4XLarge": 30.98,
      "5XLarge": 34.98,
      "6XLarge": 36.98,
    },
  },
  {
    code: "LST650",
    name: "Sport-Tek® Polo Shirt",
    fullname: "Sport-Tek® Sport-Wick Polo",
    colors: ["Black", "Iron Grey", "White"],
    type: "womens",
    default_color: "Black",
    sizes: {
      Small: 22.99,
      Medium: 22.99,
      Large: 22.99,
      XLarge: 22.99,
      "2XLarge": 24.98,
      "3XLarge": 28.98,
      "4XLarge": 30.98,
    },
  },
  {
    code: "TST650",
    name: "Sport-Tek® Tall Polo Shirt",
    fullname: "Sport-Tek® Tall Sport-Wick Polo",
    colors: ["Black", "Iron Grey", "White"],
    type: "mens",
    default_color: "Black",
    sizes: {
      LT: 24.98,
      XLT: 26.98,
      "2XLT": 28.98,
      "3XLT": 30.98,
      "4XLT": 32.98,
    },
  },
  {
    code: "ST657",
    name: "Sport-Tek® Long Sleeve Polo Shirt",
    fullname: "Sport-Tek® Long Sleeve Sport-Wick Polo",
    colors: ["Black", "Iron Grey", "White"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 26.99,
      Medium: 26.99,
      Large: 26.99,
      XLarge: 26.99,
      "2XLarge": 28.99,
      "3XLarge": 29.99,
      "4XLarge": 31.99,
    },
  },
  {
    code: "NKDC1963",
    name: "Nike® Dri-FIT Polo Shirt",
    fullname: "Nike® Dri-FIT Polo",
    colors: ["Anthracite", "Black", "University Red", "White"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 36.99,
      Medium: 36.99,
      Large: 36.99,
      XLarge: 36.99,
      "2XLarge": 38.99,
      "3XLarge": 39.99,
      "4XLarge": 40.99,
      LT: 40.99,
      XLT: 42.99,
      "2XLT": 43.99,
      "3XLT": 44.99,
      "4XLT": 45.99,
    },
  },
  {
    code: "NKDC1991",
    name: "Ladies Nike® Polo",
    fullname: "Ladies Nike® Micro Pique Polo",
    colors: ["Anthracite", "Black", "University Red", "White"],
    type: "womens",
    default_color: "Black",
    sizes: {
      Small: 36.99,
      Medium: 36.99,
      Large: 36.99,
      XLarge: 36.99,
      "2XLarge": 38.99,
    },
  },
  {
    code: "L317",
    name: "Port Authority® Jacket",
    fullname: "Port Authority® Ladies Core Soft Shell Jacket",
    colors: ["Black"],
    type: "womens",
    default_color: "Black",
    sizes: {
      Small: 37.99,
      Medium: 37.99,
      Large: 37.99,
      XLarge: 37.99,
      "2XLarge": 38.99,
      "3XLarge": 39.99,
      "4XLarge": 40.99,
    },
  },
  {
    code: "J317",
    name: "Port Authority® Jacket",
    fullname: "Port Authority® Core Soft Shell Jacket",
    colors: ["Black"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 37.99,
      Medium: 37.99,
      Large: 37.99,
      XLarge: 37.99,
      "2XLarge": 38.99,
      "3XLarge": 39.99,
      "4XLarge": 40.99,
      "5XLarge": 41.99,
      "6XLarge": 42.99,
    },
  },
  {
    code: "K805",
    name: "Port Authority® 1/4-Zip Pullover",
    fullname: "Port Authority® 1/4-Zip Pullover",
    colors: ["Black"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 30.99,
      Medium: 30.99,
      Large: 30.99,
      XLarge: 30.99,
      "2XLarge": 32.99,
      "3XLarge": 33.99,
      "4XLarge": 34.99,
      "5XLarge": 35.99,
      "6XLarge": 36.99,
    },
  },
  {
    code: "L805",
    name: "Port Authority® 1/4-Zip Pullover",
    fullname: "Port Authority® 1/4-Zip Pullover",
    colors: ["Black"],
    type: "womens",
    default_color: "Black",
    sizes: {
      Small: 30.99,
      Medium: 30.99,
      Large: 30.99,
      XLarge: 30.99,
      "2XLarge": 32.99,
      "3XLarge": 33.99,
      "4XLarge": 34.99,
    },
  },
];
